import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Header extends Component {

    constructor () {
        super();
    }

    render () {
        return (
          <div className="navbar p-0 navbar-expand-sm navbar-toggleable-sm navbar-light box-shadow bg-blue-900 text-gray-50" aria-labelledby="main-nav">
            <div className="mx-auto container px-[15px] pb-1">
              <div className="mx-auto flex flex-col md:pb-0 md:flex-row title-font font-medium items-center text-gray-900 focus:outline-none cursor-pointer w-full min-h-[64px]">
                <a href="https://octo.dc.gov/" target="_blank" rel="noreferrer"><img className="h-16" src="../assets/octo_logo.png" alt="OCTO logo"/></a>
                <span id="main-nav" className="ml-4 text-xl md:text-2xl lg:text-3xl w-auto text-white font-normal" tag={Link} to="/">MAR 2 Batch Geocoder Portal</span>
              </div>
            </div>
          </div>
        )
    }
 
}
