import React, { Component } from 'react';
import BatchGeocodeForm from '../components/BatchGeocodeForm';

export class BatchGeocode extends Component {

  static displaname = BatchGeocode.name;

  render() {
    return (
      <section className="text-gray-600 body-font mx-auto grow w-full">
        <div className="mx-auto px-3 h-full">
          <div className="py-4 mx-auto h-full">    
            <BatchGeocodeForm
              onChangeForm={ this.onChangeForm }>
            </BatchGeocodeForm>
          </div>
        </div>
      </section>
    );
  }
}