import React, { useRef, useState, useEffect } from "react"
import { Button, Slider, Checkbox, FormGroup, FormControlLabel, Tooltip  } from "@material-ui/core";
import { InfoRounded, PlusOneSharp  } from "@material-ui/icons";
import axios from "axios"
import * as XLSX from 'xlsx/xlsx.mjs';
import configData from '../configs/config.json';
import { Link } from 'react-router-dom';
import Papa, { parse } from "papaparse";
// import Tabs from "../../src/components/Tabs";
// import { ReactDOM } from "react-dom";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs"
import PropTypes from 'prop-types';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        // <Box sx={{ p: 3 }}>
          // <Typography>{children}</Typography>
          <div>{children}</div>
        // </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BatchGeocodeForm = ({onChangeForm, submitForm }) => {

    const [allGeocoded, setAllGeocoded] = useState([]);
    const [locations, setLocations] = useState([]);
    const [blocks, setBlocks] = useState([]);
    const [intersections, setIntersections] = useState([]);
    const [statistics, setStatistics] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isValidateLoading, setIsValidateLoading] = useState(false);
    const [useMinScore, setUseMinScore] = useState(false);
    const [minScore, setMinScore] = useState(92);
    const [err, setErr] = useState('');
    const [valErr, setValErr] = useState('');
    const [value, setValue] = React.useState(0);

    // geocode
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState();
    const [inputFileName, setInputFileName] = useState(); 
    const [fileType, setFileType] = useState();
    const [fileSize, setFileSize] = useState();
    const [fileExtension, setFileExtension] = useState();

    // csv file parse error
    const [csvParseError, setCsvParseError] = useState('');

    // validate file
    const [validationFile, setValidationFile] = useState(null);
    const [validationFileName, setValidationFileName] = useState();
    const [inputvalidationFileName, setValidationInputFileName] = useState(); 
    const [validationFileType, setValidationFileType] = useState();
    const [validationFileSize, setValidationFileSize] = useState();
    const [validationFileExtension, setValidationFileExtension] = useState();

    const fileInput = useRef(null);
    const fileValidationInput = useRef(null);

    const acceptedFileTypes = ['csv', 'xlsx'];
    const csvTemplateFile = 'assets/DCGeocoderBatch_CSV_template.csv';
    const xlsxTemplateFile = 'assets/DCGeocoderBatch_XLSX_template.xlsx';

    //output xlsx file
    const outputFileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const outputFileExtension = ".xlsx";

    // headers
    const reorderedHeader = configData.REORDERED_HEADER.split(",");
    const reorderedAddressHeader = configData.REORDERED_ADDRESS_HEADER.split(",");
    const reorderedBlockHeader = configData.REORDERED_BLOCK_HEADER.split(",");
    const reorderedIntersectionHeader = configData.REORDERED_INTERSECTION_HEADER.split(",");
    const customHeader = configData.CUSTOM_HEADER.split(",");
    const customAddressHeader = configData.CUSTOM_ADDRESS_HEADER.split(",");
    const customBlockHeader = configData.CUSTOM_BLOCK_HEADER.split(",");
    const customIntersectionHeader = configData.CUSTOM_INTERSECTION_HEADER.split(",");

    const tableHeadersAll = configData.PREVIEW_HEADER;
    const tableHeaders = configData.PREVIEW_HEADER;
    const tableHeadersBlock = configData.PREVIEW_HEADER_BLOCKS;
    const tableHeaderIntersection = configData.PREVIEW_HEADER_INTERSECTION;

    const handleTabChange = (event, newValue) => {
      setValue(newValue);
    };

    const handleChange = (e) => {
      if (e.target.files[0] != undefined) {
        // reset values
        setStatistics({});
        setErr('');
        setCsvParseError('');
        setValErr('');
        setAllGeocoded([]);
        setLocations([]);
        setBlocks([]);
        setIntersections([]);
        resetValidationFileInput();

        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
        setInputFileName(e.target.files[0].name);      
        setFileType(e.target.files[0].type);
        setFileSize(e.target.files[0].size);

        let filePieces = e.target.files[0].name.split('.');
        let fileExt = filePieces[filePieces.length -1];
        setFileExtension(filePieces[filePieces.length -1])

        if (!acceptedFileTypes.includes(fileExt)) {
          setErr('File type not accepted!');
          setValErr('');
        }
      }
    }

    const handleValidationChange = (e) => {
      if (e.target.files[0] != undefined) {
       
        // reset values
        setStatistics({});
        setValErr('');
        setErr('');
        setCsvParseError('');
        setAllGeocoded([]);
        setLocations([]);
        setBlocks([]);
        setIntersections([]);
        resetFileInput();

        setValidationFile(e.target.files[0]);
        setValidationFileName(e.target.files[0].name);
        setValidationInputFileName(e.target.files[0].name);      
        setValidationFileType(e.target.files[0].type);
        setValidationFileSize(e.target.files[0].size);     

        let filePieces = e.target.files[0].name.split('.');
        let fileExt = filePieces[filePieces.length -1];
        setValidationFileExtension(filePieces[filePieces.length -1])

        if (!acceptedFileTypes.includes(fileExt)) {
          setValErr('File type not accepted!');
          setErr('');
        }
      }
    }

    const resetFileInput = () => {
      fileInput.current.value = null;
      setFile(null);
      setFileName();
      setInputFileName();      
      setFileType();
      setFileSize();
      setFileExtension();
    }

    const resetValidationFileInput = () => {
      fileValidationInput.current.value = null;
      setValidationFile(null);
      setValidationFileName();
      setValidationInputFileName();      
      setValidationFileType();
      setValidationFileSize();
      setValidationFileExtension();
    }    

    const defaultValues = {
        filename: '',
        addressSeparator: '||',
        chunkSeparator: ':'
    }

    const [formValues, setFormValues] = useState(defaultValues);

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setFormValues({
        ...formValues,
        [name]: value
        })
    }

    // data validation
    const handleValidateClick = async() => {
      setIsValidateLoading(true);

      // reset values
      setStatistics({});
      setErr('');
      setCsvParseError('');
      setAllGeocoded([]);
      setLocations([]);
      setBlocks([]);
      setIntersections([]);

      let failErrorMessage = '';

      try {
        
            // create form object
            const formData = new FormData();
            formData.append("formFile", validationFile);
            formData.append("fileName", validationFileName);
            formData.append("fileType", validationFileType);
            formData.append("fileSize", validationFileSize);

 
            // const outputFileName = fileName.substr(0, fileName.lastIndexOf(".")) + '_Cleaned' + outputFileExtension;
            const outputFileName = validationFileName.substr(0, validationFileName.lastIndexOf(".")) + '_Cleaned.csv';

            // console.log(outputFileName);
            // console.log(process.env.REACT_APP_API_BATCHCLEANING_URL);
          
            await axios.post(process.env.REACT_APP_API_BATCHCLEANING_URL, formData)
      
          .then(response => {

            // console.log('in response');
            // console.log(response.data);

            const data = new Blob([response.data], {type: outputFileType})
                
            //Download
            const url = window.URL.createObjectURL(data);
            const link = document.createElement('a');
            link.href=url;

            link.setAttribute('download', outputFileName);
            document.body.appendChild(link);
            link.click();            

          })
          .catch(error => {
            // if network error - handle one way - unable to get clear message out
            if (error.message == 'Network Error') {
              failErrorMessage = 'Network Error<br/><span style="font-style: italic;">*Note: If file was updated outside of browser after selection, please try uploading again.</span>'
            } else if (error.response) {
              // if other error - handle with returned error message
              if (error.response.status === 400)
              {
                failErrorMessage = 'Status Code: ' + error.response.status + '  Bad request -   ' + error.response.data
              }
              else if((error.response.status === 404))
              {
                failErrorMessage = 'Status Code: ' + error.response.status + ' - ' + error.response.data;
              }
            }

            setErr(failErrorMessage);
          });
   
      
      } catch (err) {
      setErr(err.message);
      } finally {
          setErr(failErrorMessage);
          // resetFileInput();
          resetValidationFileInput();          
          setIsValidateLoading(false);
      }

    };

    // Geocode file
    const handleClick = async() => {
      setIsLoading(true);

      let resultStatistics = '';
      let failErrorMessage = '';
      let csvParseErrorMessage = '';

      try {
        // reset values
        setStatistics({});
        setErr('');
        setCsvParseError('');
        setAllGeocoded([]);
        setLocations([]);
        setBlocks([]);
        setIntersections([]);

        // create form object
        const formData = new FormData();
        formData.append("formFile", file);
        formData.append("fileName", fileName);
        formData.append("fileType", fileType);
        formData.append("fileSize", fileSize);

        // if use min score - pass that along
        if (useMinScore) {
          formData.append("minScore", minScore);
        }

        const request_start_at = performance.now();
        const outputFileName = fileName.substr(0, fileName.lastIndexOf(".")) + '_Geocoded' + outputFileExtension;

        
        if (fileExtension.toLowerCase() === 'xlsx') // if file is excel
        {
          await axios.post(process.env.REACT_APP_API_BATCHGEOCODE_URL, formData)
            .then(response => {
              // read input xlsx file
              const reader = new FileReader();
  
              reader.onload = (e) => {
                // const bstr = e.target.result;
                const bstr = reader.result;
                const wb = XLSX.read(bstr, { type: "binary" });  // workbook
                const wsname = wb.SheetNames[0];
                // console.log(wsname);
                const ws = wb.Sheets[wsname];         // worksheet 
  
                // get input excel file header columns array
                const arrayInHeaderColumns = XLSX.utils.sheet_to_json(wb.Sheets[wsname], { header: 1 })[0];
                console.log(arrayInHeaderColumns);

                // console.log(JSON.parse(response.data[0]).root.rows.length);
                // console.log('we are in excel process');
                // console.log(response.data[0]);
  
                processAndGenerateXLS(response, ws, arrayInHeaderColumns, outputFileName);
              };
              reader.readAsBinaryString(file);  // excel file read using FileReader

              // create stats
              const request_end_at = performance.now();
              const request_duration = request_end_at - request_start_at;
              const requestDurationFormatted = new Date(request_duration).toISOString().slice(11, 19).toString();
    
              // set statstic object
              // console.log(JSON.parse(response.data[0]).root.rows.length);
                          
              let arrAllAddress = JSON.parse(response.data[0].replace(/\\/g,"/"));
              // let arrAllAddress = safeJSONParse(response.data[0]);            
              // let geoCodedNum = JSON.parse(response.data[1]).root.rows.filter(({_Score}) => _Score != undefined).length + JSON.parse(response.data[2] != null).root.rows.filter(({_Score}) => _Score != undefined).length
              // let geoCodedAddressOnlyNum = JSON.parse(response.data[1]).root.rows.filter(({_Score}) => _Score != undefined).length 
              //address only geocoded total
              let geoCodedAddressOnlyNum = JSON.parse(response.data[1].replace(/\\/g,"/")).root.rows.filter(({_Score}) => _Score != undefined).length 
              
              // blocks geocoded total
              let geoCodedBlockNum = response.data[2] != null ? JSON.parse(response.data[2].replace(/\\/g,"/")).root.rows.filter(({_Score}) => _Score != undefined).length : 0
              
              // intersections geocoded total
              let geoCodeIntersectionNum = response.data[3] != null ? JSON.parse(response.data[3].replace(/\\/g,"/")).root.rows.filter(({_Score}) => _Score != undefined).length : 0
              
              // all geocoded toral
              let geoCodedNum = geoCodedAddressOnlyNum + geoCodedBlockNum + geoCodeIntersectionNum

              resultStatistics = {
                inputFilename: inputFileName,
                outputFilename: outputFileName,
                filesize: fileSize,
                time: requestDurationFormatted,
                number: arrAllAddress.root.rows.length,
                geocoded: geoCodedNum              
              }
    
              // following are for screen tabular display data
              
              // all geocoded data
              // setAllGeocoded(JSON.parse(response.data[0]).root.rows);
              // setAllGeocoded(safeJSONParse(response.data[0]).root.rows);              
              setAllGeocoded(JSON.parse(response.data[0].replace(/\\/g,"/")).root.rows);                            

              // locations street addresses gecoded data
              setLocations(JSON.parse(response.data[1].replace(/\\/g,"/")).root.rows);            
              
              // blocks geocoded data
              setBlocks(JSON.parse(response.data[2].replace(/\\/g,"/")).root.rows);            

              // intersections geocoded data
              setIntersections(JSON.parse(response.data[3].replace(/\\/g,"/")).root.rows);                      

            })
            .catch(error => {
              // if network error - handle one way - unable to get clear message out
              if (error.message == 'Network Error') {
                failErrorMessage = 'Network Error<br/><span style="font-style: italic;">*Note: If file was updated outside of browser after selection, please try uploading again.</span>'
              } else if (error.response) {
                // if other error - handle with returned error message
                if (error.response.status === 400)
                {
                  failErrorMessage = 'Status Code: ' + error.response.status + '  Bad request -   ' + error.response.data
                }
                else if((error.response.status === 404))
                {
                  failErrorMessage = 'Status Code: ' + error.response.status + ' - ' + error.response.data;
                }
              }

              setErr(failErrorMessage);
            });
        }
        else if (fileExtension.toLowerCase() === 'csv') // if file is csv
        {

          // parse validate
          const isCSVParseValid = await parseValidateCSVFile(file);            
          if (isCSVParseValid !== 'Valid')
          {
            csvParseErrorMessage = isCSVParseValid;
            // console.log(csvParseErrorMessage);  
          }

          // if csv parse is valid
          if (isCSVParseValid === 'Valid')
          {                

            // console.log('we are here');
            await axios.post(process.env.REACT_APP_API_BATCHGEOCODE_URL, formData)
            .then(response => {

              // CSVProcess();
              const reader = new FileReader();

              reader.onload = (e) => {
      
                // CSV Papa parser -  npm install papaparse
                // added config skipEmptyLines = true to deal with empty row (line) at the end of file
                // as well as any empty rows (lines) in file   
                const csv = Papa.parse(reader.result, {
                  header: true,
                  skipEmptyLines: true
                });                
                
                const parsedData = csv?.data;         
                
                // json to worksheet
                const ws = XLSX.utils.json_to_sheet(parsedData, { skipHeader: false }) // do not skip header
                
                // get input csv file header columns array
                const arrayInHeaderColumnsCSV = XLSX.utils.sheet_to_json(ws, { header: 1 })[0];
                // console.log(arrayInHeaderColumnsCSV);      

                processAndGenerateXLS(response, ws, arrayInHeaderColumnsCSV, outputFileName);
              };          
              reader.readAsText(file);

              // create stats
              const request_end_at = performance.now();
              const request_duration = request_end_at - request_start_at;
              const requestDurationFormatted = new Date(request_duration).toISOString().slice(11, 19).toString();
    
              // set statstic object
              // console.log(JSON.parse(response.data[0]).root.rows.length);
                          
              let arrAllAddress = JSON.parse(response.data[0].replace(/\\/g,"/"));
              // let arrAllAddress = safeJSONParse(response.data[0]);            
              // let geoCodedNum = JSON.parse(response.data[1]).root.rows.filter(({_Score}) => _Score != undefined).length + JSON.parse(response.data[2] != null).root.rows.filter(({_Score}) => _Score != undefined).length
              // let geoCodedAddressOnlyNum = JSON.parse(response.data[1]).root.rows.filter(({_Score}) => _Score != undefined).length 
              //address only geocoded total
              let geoCodedAddressOnlyNum = JSON.parse(response.data[1].replace(/\\/g,"/")).root.rows.filter(({_Score}) => _Score != undefined).length 
              
              // blocks geocoded total
              let geoCodedBlockNum = response.data[2] != null ? JSON.parse(response.data[2].replace(/\\/g,"/")).root.rows.filter(({_Score}) => _Score != undefined).length : 0
              
              // intersections geocoded total
              let geoCodeIntersectionNum = response.data[3] != null ? JSON.parse(response.data[3].replace(/\\/g,"/")).root.rows.filter(({_Score}) => _Score != undefined).length : 0
              
              // all geocoded toral
              let geoCodedNum = geoCodedAddressOnlyNum + geoCodedBlockNum + geoCodeIntersectionNum

              resultStatistics = {
                inputFilename: inputFileName,
                outputFilename: outputFileName,
                filesize: fileSize,
                time: requestDurationFormatted,
                number: arrAllAddress.root.rows.length,
                geocoded: geoCodedNum              
              }
    
              // following are for screen tabular display data
              
              // all geocoded data
              // setAllGeocoded(JSON.parse(response.data[0]).root.rows);
              // setAllGeocoded(safeJSONParse(response.data[0]).root.rows);              
              setAllGeocoded(JSON.parse(response.data[0].replace(/\\/g,"/")).root.rows);                            

              // locations street addresses gecoded data
              setLocations(JSON.parse(response.data[1].replace(/\\/g,"/")).root.rows);            
              
              // blocks geocoded data
              setBlocks(JSON.parse(response.data[2].replace(/\\/g,"/")).root.rows);            

              // intersections geocoded data
              setIntersections(JSON.parse(response.data[3].replace(/\\/g,"/")).root.rows);                      

            })
            .catch(error => {
              // if network error - handle one way - unable to get clear message out
              if (error.message == 'Network Error') {
                failErrorMessage = 'Network Error<br/><span style="font-style: italic;">*Note: If file was updated outside of browser after selection, please try uploading again.</span>'
              } else if (error.response) {
                // if other error - handle with returned error message
                if (error.response.status === 400)
                {
                  failErrorMessage = 'Status Code: ' + error.response.status + '  Bad request -   ' + error.response.data
                }
                else if((error.response.status === 404))
                {
                  failErrorMessage = 'Status Code: ' + error.response.status + ' - ' + error.response.data;
                }
              }

              setErr(failErrorMessage);
            });
          } 
        }               
      } catch (err) {
          setErr(err.message);
          // console.log(err);
      } finally {
          setStatistics(resultStatistics);
          setErr(failErrorMessage);
          setCsvParseError(csvParseErrorMessage);
          resetFileInput();
          setIsLoading(false);
          // console.log(err);
          // console.log('here in finally');
      }
    }

    // csv parse validation
    const parseValidateCSVFile = (file) => { 
      const reader = new FileReader();                     
      return new Promise((resolve, reject)=> {
        // const reader = new FileReader();
        reader.onload = (e) => {

          // CSV Papa parser -  npm install papaparse
          // added config skipEmptyLines = true to deal with empty row (line) at the end of file
          // as well as any empty rows (lines) in file          
          const csv = Papa.parse(reader.result, {
            header: true,
            skipEmptyLines: true
          });

          if (csv?.errors.length !== 0) 
          {
            var errorCount = csv?.errors.length;
            // console.log(errorCount);


            const errorList = [];
            var errorMessage = 'Error count: ' + errorCount + '<br/>';
            errorList.push(errorMessage);
            for (let i = 0; i < errorCount; i++) {
              // (csv.errors[i].row + 2) adding 2 here to give correct rown number - one for header and one for rows starting at zero
                errorMessage = 'Error ' + (i + 1) + ': '  + csv.errors[i].message + ', Row Num: ' + (csv.errors[i].row + 2) + ' Error Type: ' + csv.errors[i].type;
                // console.log(errorMessage);

                // errorList.push('<li>'+  errorMessage + '</li>');
                errorList.push( errorMessage + '<br/>');
            }
            // console.log(errorList.join('')) ;           
 
            var parseValid = errorList.join('');
          }
          else
          {
            var parseValid = 'Valid';
          }
          resolve(parseValid);
        };
        reader.readAsText(file);
      })
    }

    function safeJSONParse(jsonString) {
      try {
        // example
        // var a = String.raw`This is my \ string`.replace(/\\/g,"/");
        // console.log(a);

        // Step 1: Unescape JSON strings to handle double-escaped characters      
        const unescapedJSON = jsonString.replace(/\\/g, (match) => {
          switch (match) {
            // case '\\"': return '"';
            // case '\\n': return '\n';
            // case '\\t': return '\t';
            case '\\': return '/';            
            // Add more escape sequences as needed
            default: return match[1]; // Remove the backslash
          }
        });
    
        // Step 2: Parse the unescaped JSON
        const parsedData = JSON.parse(unescapedJSON);
        // console.log(parsedData);
        // console.log('here we are yes');
    
        return parsedData;
      } catch (error) {
        // console.error('Error parsing JSON:', error);
        return null; // Handle the error gracefully or throw an exception if necessary
      }
    }
    
    function processAndGenerateXLS (response, ws, arrayInHeader, outputFileName) {
      
      // RP - 05132024 - take array and lowercase specific string element in array, in this case "Zipcode"
      // var arr_lower = arrayInHeader.map(item => item.toLowerCase());
      // added this line to fix the issue of MAR_ZIPCODE is not being populated
      // It only happens for zipcode element and only happens if the input file header is "Zipcode"
      // and only for "data" tab in xlsx output file where the input file headers and data added
      // to output file.  for all these following variations no problem.  Also for "address" tab no issue
      // where the header and data from input file is not there
      // "zipcode", zipCode", "zIpCode" "ZIPCODE" etc. no issue.  Issue is only for "Zipcode"

      var arr_lower = arrayInHeader.map(item => item === "Zipcode" ? item.toLowerCase() : item);
      // console.log(arr_lower); 

      // takes header from original document and concatenates custom and reordered headers
      const newReorderedHeader = arr_lower.concat(reorderedHeader);

      const newCustomHeader = arrayInHeader.concat(customHeader);  // address header for all input addressses

      let custom = [ newCustomHeader ];  // address heading: array of arrays
      let customAddress = [ customAddressHeader]; // only address results heading: array of arrays
      let customBlock = [ customBlockHeader ]; // block heading array of arrays
      let customIntersection = [ customIntersectionHeader]; // intersection heading array of arrays

      try {
        // deleting InputAddress field from Json Array that is no more needed
        // deleting InputAddress field also fixes 
        // the extra column showing up in excel output result
        // let arr = response.data.root.rows;

        // console.log(JSON.stringify(response));

        // let arrAddress = JSON.parse(response.data[0].replace(/\\/g,"/").replace(/'\\\\'/g,""));
        let arrAddress = JSON.parse(response.data[0].replace(/\\/g,"/"));
        // console.log('here we are too' + arrAddress);
        console.log(JSON.stringify(arrAddress));
        // let arrAddress = JSON.parse(response.data[0]);
        // let arrAddress = safeJSONParse(response.data[0]);

        let resultJson = arrAddress.root.rows.map(o => {
          let obj = Object.assign({}, o);
          delete obj.InputAddress;
          return obj;
        });            

        // console.log('resultJson' + resultJson);
        XLSX.utils.sheet_add_json(ws, resultJson, { origin: 'A2', skipHeader: true, header: newReorderedHeader });
        XLSX.utils.sheet_add_aoa(ws, custom); //heading: array of arrays
        const wb1 = { Sheets: { data: ws }, SheetNames: ["data"] };
        // XLSX.utils.book_append_sheet(wb1, ws);

        let arrOnlyAddress = null;
        if (response.data[1] !== null)
        {
          arrOnlyAddress = JSON.parse(response.data[1].replace(/\\/g,"/"));
          // if (arrOnlyAddress != null)
          // {
          // only street addresses - address sheet
          let resultAddressJson = arrOnlyAddress.root.rows.map(o => {
            let obj = Object.assign({}, o);
            // delete obj.InputAddress;
            return obj;
          });    
          
          const wsAddress = XLSX.utils.json_to_sheet([]);
          XLSX.utils.sheet_add_json(wsAddress, resultAddressJson, { origin: 'A2', skipHeader: true, header: reorderedAddressHeader });
          XLSX.utils.sheet_add_aoa(wsAddress, customAddress); //heading: array of arrays
          // const wb1 = {Sheets: {data: wsAddress}, SheetNames: ["address"]}
          XLSX.utils.book_append_sheet(wb1, wsAddress, "address"); 
        }       
          
        // block
        let arrBlock = null;
        if (response.data[2] !== null)
        {
          let arrBlock = JSON.parse(response.data[2].replace(/\\/g,"/"));
          // }
          // // console.log(arrBlock);

          // if (arrBlock != null)
          // {
          let resultBlockJson = arrBlock.root.rows.map(o => {
            let obj = Object.assign({}, o);
            // delete obj.InputAddress;
            return obj;
          });
          
          const wsBlock = XLSX.utils.json_to_sheet([]);
          XLSX.utils.sheet_add_json(wsBlock, resultBlockJson, { origin: 'A2', skipHeader: true, header: reorderedBlockHeader });
          XLSX.utils.sheet_add_aoa(wsBlock, customBlock); //heading: array of arrays
          XLSX.utils.book_append_sheet(wb1, wsBlock, "block");
        }

        // intersection
        let arrIntersection = null;
        if (response.data[3] !== null)
        {
          arrIntersection = JSON.parse(response.data[3].replace(/\\/g,"/"));
          // }
          // console.log(arrIntersection);

          // if (arrIntersection != null)
          // {
          let resultIntersectionJson = arrIntersection.root.rows.map(o => {
            // console.log(o);
            let obj = Object.assign({}, o);
            // console.log(obj);
            return obj;
          });

          const wsIntersection = XLSX.utils.json_to_sheet([]);
          XLSX.utils.sheet_add_json(wsIntersection, resultIntersectionJson, {origin: 'A2', skipHeader: true, header: reorderedIntersectionHeader});
          XLSX.utils.sheet_add_aoa(wsIntersection, customIntersection);
          XLSX.utils.book_append_sheet(wb1, wsIntersection, "intersection");
        }

        // write
        const excelBuffer = XLSX.write(wb1, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: outputFileType });
             
        //Download
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href=url;
        // link.setAttribute('download', fileNameExcel + fileExtension);
        link.setAttribute('download', outputFileName);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        // console.log(error);
        // console.log(error.message.match(/.{1,50}/g) + "<br/>");
        // setErr('Error creating XLSX file with geocoded data: <br/>' + error.message.match(/.{1,30}/g) + "<br/>");
        // setErr('Error creating XLSX file of geocoded data: <br/>' + error.message);
        setErr('Error creating XLSX file of geocoded data: ' + error.message);
      }
    }

    function valuetext(value) {
      return `${value}`;
    }

    return (
      <div className="flex items-start justify-start flex-col lg:flex-row h-full">
        <div className="flex flex-col space-y-8">
        <div className="">
          <label className="block mb-2 text-lg font-medium text-gray-900" htmlFor="validationFileName">First Validate Your Data (optional)</label>
          <div className="">
              <input className="relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-neutral-300 bg-white bg-clip-padding px-3 py-1.5 text-sm font-normal text-neutral-700 outline-none transition duration-300 ease-in-out file:-mx-3 file:-my-1.5 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-slate-700 file:px-3 file:py-1.5 file:text-white file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-slate-500 focus:border-primary focus:bg-white focus:text-white focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none" 
                aria-describedby="file_input_help" 
                id="validationFileName"
                type="file"
                ref={fileValidationInput}
                onChange= { handleValidationChange }/>
                <p className="mt-1 text-sm text-gray-500" id="validationFileName">File types accepted: { acceptedFileTypes.join(', ').toUpperCase() }</p>
          </div>
          <Button 
            variant="contained" 
            color="primary"
            className="w-full mt-2"
            disabled={ isValidateLoading || validationFile == null  || !acceptedFileTypes.includes(validationFileExtension) }
            onClick={ handleValidateClick }>
              Validate
          </Button>
          <div className="mt-6" hidden={ Object.keys(valErr).length === 0 }>
            <div className="text-lg font-semibold">Data Validation Error:</div> 
            <div className="font-bold"><span dangerouslySetInnerHTML={{ __html: valErr }}></span></div>            
          </div>                    
        </div>
        <div className="">
          <label className="block mb-2 text-lg font-medium text-gray-900" htmlFor="originalFileName">Then Geocode Your Data</label>
          <div className="">
            <input className="relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-neutral-300 bg-white bg-clip-padding px-3 py-1.5 text-sm font-normal text-neutral-700 outline-none transition duration-300 ease-in-out file:-mx-3 file:-my-1.5 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-slate-700 file:px-3 file:py-1.5 file:text-white file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-slate-500 focus:border-primary focus:bg-white focus:text-white focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none" 
              aria-describedby="file_input_help" 
              id="originalFileName"
              type="file"
              ref={fileInput}
              onChange= { handleChange }/>
              <p className="mt-1 text-sm text-gray-500" id="originalFileName">File types accepted: { acceptedFileTypes.join(', ').toUpperCase() }</p>
              <div className="py-2">
                <FormGroup className="flex !flex-row">
                  <FormControlLabel control={
                    <Checkbox
                      color="primary"
                      checked={ useMinScore }
                      onChange={ () => { setUseMinScore(!useMinScore) } }
                      inputProps={{ 'aria-label': 'Use Min Match Score' }}
                    />
                  } label="Use Minimum Match Score" />
                  <div className="h-full pt-2">
                    <Tooltip className="text-sm" title="The match score is the relative accuracy of the point found based on the information given.  The default scoring threshold for returned records is 60." arrow>
                      <InfoRounded className="cursor-pointer" color="primary" />
                    </Tooltip>
                  </div>
                </FormGroup>
                <div className="flex justify-end">
                  <div className={ "w-1/5 text-center " + (useMinScore ? 'text-blue-800 font-semibold' : 'hidden')}>
                    { minScore }
                  </div>
                  <div className="w-4/5">
                    <Slider
                      aria-label="Score Threshold"
                      value={ minScore }
                      getAriaValueText={ valuetext }
                      valueLabelDisplay="auto"
                      onChange={ (evt, val) => { setMinScore(val) }}
                      marks
                      min={80}
                      max={100}
                      disabled={ !useMinScore }
                    />
                  </div>
                </div>
                <p className="w-full mt-1 text-sm text-gray-500 text-right">Recommended to maintain a score of 92% or greater</p>
              </div>
          </div>
          <div className="text-right flex flex-col justify-end mb-2">
            <a className="underline" href={ csvTemplateFile } download>Download CSV Template</a>
            <a className="underline" href={ xlsxTemplateFile } download>Download XLSX Template</a>
          </div>
          <Button 
            variant="contained" 
            color="primary"
            className="w-full mt-2"
            disabled={ isLoading || file == null  || !acceptedFileTypes.includes(fileExtension) }
            onClick={ handleClick }>
              Geocode
          </Button>
          <div className="mt-6" hidden={ !isLoading }> 
            <div className="w-full mx-auto flex flex-col items-center">
                <span>Loading...</span>
                <img className="w-24 h-24" src={ 'loading.gif' } alt="Loading" />
              </div> 
          </div>
          <div className="mt-6" hidden={ !isValidateLoading }> 
            <div className="w-full mx-auto flex flex-col items-center">
                <span>Validating...</span>
                <img className="w-24 h-24" src={ 'loading.gif' } alt="Loading" />
              </div> 
          </div>                 
          <div className="mt-6" hidden={ Object.keys(statistics).length === 0 }>
            <div className="text-lg font-semibold">Batch Process Completed</div>
            <div>Input File: <span>{ statistics.inputFilename }</span></div>
            <div>Ouput File: <span>{ statistics.outputFilename }</span></div>
            <div>File size (bytes): <span>{ statistics.filesize }</span></div>
            <div>Process time (in hours, minutes, seconds): <span>{ statistics.time }</span></div>
            <div>Records processed: <span>{ statistics.number }</span></div>
            <div>Records geocoded: <span>{ statistics.geocoded }</span></div>                        
          </div>
          <div className="mt-6" hidden={ Object.keys(err).length === 0 }>
            <div className="text-lg font-semibold">Process Error:</div>
            <div className="max-w-[340px] font-semibold"><span break-words whitespace-normal dangerouslySetInnerHTML={{ __html: err }}></span></div>            
          </div>                     
          <div className="mt-6" hidden={ Object.keys(csvParseError).length === 0 }>
            <div className="text-lg font-semibold">CSV File Parse Error:</div>
            <div className="font-semibold"><span dangerouslySetInnerHTML={{ __html: csvParseError }}></span></div>            
          </div> 
        </div>
        </div>

        <div className="w-full lg:ml-4 lg:pl-4 lg:pb-3 lg:w-2/3 lg:border-l h-full flex flex-col pb-2">
          {
            locations.length > 0 ? 
              <div className="overflow-hidden mb-6 md:mb-0  flex flex-col">
                <div className="text-lg font-semibold pt-4 md:pt-0">Preview Results:</div>
                <div className="text-sm italic" hidden={ locations.length <= 1000 }>Please note, only the first 1,000 records will be available for preview - the rest can be viewed in the results file.</div>
                <div className="text-lg font-semibold pt-4 md:pt-0">
                  <Tabs value={value} onChange={handleTabChange} 
                    TabIndicatorProps={{
                      style: {
                        backgroundColor: "#1E3A8A"
                      }
                    }}
                    aria-label="view result tabs"
                  >
                    <Tab label="All Geocoded" {...a11yProps(0)} />
                    <Tab label="Street Addresses" {...a11yProps(1)} />
                    <Tab label="Blocks" {...a11yProps(2)} />
                    <Tab label="Intersections" {...a11yProps(3)} />
                  </Tabs>
                </div>
                <div className="overflow-auto grow pb-0 max-h-[28rem] h-3/4">
                <TabPanel value={value} index={0}>                                  
                    <table className="whitespace-nowrap min-w-full bg-white">
                      <thead>
                        <tr>
                          <th className="bg-blue-100 border-b text-left p-2"></th>
                          {
                            tableHeadersAll.map(headerItem => {
                              return <th key={ 'header_' + headerItem.field } className="bg-blue-100 border-b text-left p-2">{ headerItem.returnField }</th>
                            })
                          }
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-300">
                        {
                          allGeocoded.slice(0, 1000).map((allGeocodedObj, index) => {
                            return <tr key={ 'tr_' + index }>
                              <td className="border-b px-1.5"><div className="w-9">{ index + 1 }</div></td>
                              {
                                tableHeadersAll.map((field, index2) => {
                                  return <td key={ 'td_' + index + index2 } className="border-b px-1.5">{ allGeocodedObj[field.field] }</td>
                                })
                              }
                            </tr>
                          })
                        }
                      </tbody>
                    </table>
                  </TabPanel>                  
                  <TabPanel value={value} index={1}>                                  
                    <table className="whitespace-nowrap min-w-full bg-white">
                      <thead>
                        <tr>
                          <th className="bg-blue-100 border-b text-left p-2"></th>
                          {
                            tableHeaders.map(headerItem => {
                              return <th key={ 'header_' + headerItem.field } className="bg-blue-100 border-b text-left p-2">{ headerItem.returnField }</th>
                            })
                          }
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-300">
                        {
                          locations.slice(0, 1000).map((locationObj, index) => {
                            return <tr key={ 'tr_' + index }>
                              <td className="border-b px-1.5"><div className="w-9">{ index + 1 }</div></td>
                              {
                                tableHeaders.map((field, index2) => {
                                  return <td key={ 'td_' + index + index2 } className="border-b px-1.5">{ locationObj[field.field] }</td>
                                })
                              }
                            </tr>
                          })
                        }
                      </tbody>
                    </table>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                  <table className="whitespace-nowrap min-w-full bg-white">
                      <thead>
                        <tr>
                          <th className="bg-blue-100 border-b text-left p-2"></th>
                          {
                            tableHeadersBlock.map(headerItem => {
                              return <th key={ 'header_' + headerItem.field } className="bg-blue-100 border-b text-left p-2">{ headerItem.returnField }</th>
                            })
                          }
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-300">
                        {
                          blocks.slice(0, 1000).map((blockObj, index) => {
                            return <tr key={ 'tr_' + index }>
                              <td className="border-b px-1.5"><div className="w-9">{ index + 1 }</div></td>
                              {
                                tableHeadersBlock.map((field, index2) => {
                                  return <td key={ 'td_' + index + index2 } className="border-b px-1.5">{ blockObj[field.field] }</td>
                                })
                              }
                            </tr>
                          })
                        }
                      </tbody>
                    </table>                    
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <table className="whitespace-nowrap min-w-full bg-white">
                      <thead>
                        <tr>
                          <th className="bg-blue-100 border-b text-left p-2"></th>
                          {
                            tableHeaderIntersection.map(headerItem => {
                              return <th key={ 'header_' + headerItem.field } className="bg-blue-100 border-b text-left p-2">{ headerItem.returnField }</th>
                            })
                          }
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-300">
                        {
                          intersections.slice(0, 1000).map((intersectionObj, index) => {
                            return <tr key={ 'tr_' + index }>
                              <td className="border-b px-1.5"><div className="w-9">{ index + 1 }</div></td>
                              {
                                tableHeaderIntersection.map((field, index2) => {
                                  return <td key={ 'td_' + index + index2 } className="border-b px-1.5 ">{ intersectionObj[field.field] }</td>
                                })
                              }
                            </tr>
                          })
                        }
                      </tbody>
                    </table> 
                  </TabPanel>                                                                                       
                </div>
                {/* <span className="text-sm italic pt-2">
                  <p className="text-sm not-italic font-semibold">Disclaimer:</p>
                  Neither the District of Columbia Government nor the Office of the Chief Technology Officer make any claims as to the completeness, accuracy or content of any data contained in this application; or make any representation of any kind, including, but not limited to, warranty of the accuracy or fitness for a particular use, nor are any such warranties to be implied or inferred with respect to the information or data furnished herein. The data are subject to change as modifications and updates are complete. It is understood that the information contained in the Data Center is being used at one's own risk.
                </span> */}
                <span className="max-w-3xl w-full text-lg italic">
                {/* <p className="text-sm not-italic font-semibold">Disclaimer:</p> */}
                <p><a className="text-dark underline cursor-pointer hover:underline hover:text-blue-600" href="/faqs">Disclaimer</a></p>
                {/* Neither the District of Columbia Government nor the Office of the Chief Technology Officer make any claims as to the completeness, accuracy or content of any data contained in this application; or make any representation of any kind, including, but not limited to, warranty of the accuracy or fitness for a particular use, nor are any such warranties to be implied or inferred with respect to the information or data furnished herein. The data are subject to change as modifications and updates are complete. It is understood that the information contained in the Data Center is being used at one's own risk. */}
              </span>                
              </div>
            : 
            <div className="overflow-auto grow h-full max-h-[28rem] w-full flex flex-col justify-around items-center">
              {/* <span className="max-w-2xl w-full text-center">
                Use the tool <span className="hidden lg:inline">to the left</span><span className="inline lg:hidden">above</span> to geocode a file of 
                addresses.<br/>
                Please note, you must stay on the page to geocode the results - <br/>navigating away will disrupt the process.*/}
                {/* <div tag={Link} className="block text-dark underline cursor-pointer hover:underline hover:text-blue-600" to="/faqs">View FAQs</div> */}
                {/* <div><a className="block text-dark underline cursor-pointer hover:underline hover:text-blue-600" href="/faqs">View FAQs</a></div> 
              </span> */} 
              <span className="max-w-3xl w-full text-black text-justify">
                <p className="block mb-3">The batch geocoder enables the bulk geocoding of location records against DC’s Master Address Repository (MAR).</p>
                <p className="block mb-3">We recommend using the Data Validation process prior to geocoding your data. The Data Validation process prepares your data for successful geocoding. The validated file should be used as the input for the Geocoding process.</p>
                <p className="block mb-1">You must stay on this webpage while geocoding. Closing this webpage will disrupt the process.  <a className="text-dark underline cursor-pointer hover:underline hover:text-blue-600" href="/faqs">View FAQs</a></p>
                {/* <div tag={Link} className="block text-dark underline cursor-pointer hover:underline hover:text-blue-600" to="/faqs">View FAQs</div> */}
                {/* <a className="block text-dark underline cursor-pointer hover:underline hover:text-blue-600" href="/faqs">View FAQs</a>  */}
              </span>
              <span className="max-w-3xl w-full text-black text-justify">
                {/* If you are a DC Employee and having issues with the tool, please open a ticket via <a className="font-light text-dark hover:text-blue-600" href="https://dcgov.service-now.com/esc">OCTOHelps</a>.<br/>
                For all other users, please email the DC GIS team <a className="font-light text-dark hover:text-blue-600" href="mailTo:DC GIS team <gisgroup@dc.gov>?subject=MAR API Help">(gisgroup@dc.gov)</a> for assistance.<br/>
                We welcome your feedback to help us improve the Batch Geocoder! */}
                <label className="block mb-0 text-lg text-center font-medium text-black" htmlFor="validationFileName">Having trouble?</label>
                Start by reviewing the Batch Geocode Guide. DC government staff continuing to have issues can request assistance via <a className="text-dark hover:text-blue-600" href="https://dcgov.service-now.com/esc">OCTOHelps</a>. For all other users, please email the DC GIS team <a className="text-dark hover:text-blue-600" href="mailTo:DC GIS team <gisgroup@dc.gov>?subject=MAR API Help">(gisgroup@dc.gov)</a> for assistance. We welcome your feedback to help us improve the Batch Geocoder!
              </span> 
              <span className="max-w-3xl w-full text-lg italic">
                {/* <p className="text-sm not-italic font-semibold">Disclaimer:</p> */}
                <p><a className="text-dark underline cursor-pointer hover:underline hover:text-blue-600" href="/faqs">Disclaimer</a></p>
                {/* Neither the District of Columbia Government nor the Office of the Chief Technology Officer make any claims as to the completeness, accuracy or content of any data contained in this application; or make any representation of any kind, including, but not limited to, warranty of the accuracy or fitness for a particular use, nor are any such warranties to be implied or inferred with respect to the information or data furnished herein. The data are subject to change as modifications and updates are complete. It is understood that the information contained in the Data Center is being used at one's own risk. */}
              </span>
            </div>
          }
        </div>
      </div>
    )
}

export default BatchGeocodeForm