import React, { Component } from 'react';
import Base64EncodeForm from '../components/Base64EncodeForm';

export class Base64Encode extends Component {

  static displaname = Base64Encode.name;



  render() {
    
    return (
      <div className="Base64Encode">
        {/* <Header></Header> */}
        <h4>Base64 Encode</h4>
        <br/>        
        <div className="container mrgnbtm">
          <div className="row">
            <div className="col-md-8">
                <Base64EncodeForm 
                  onChangeForm={this.onChangeForm}
                  // submitForm={this.createUser}
                  >
                </Base64EncodeForm>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
