import React, { useRef, useState } from "react"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import TextareaAutosize from "@material-ui/core/TextareaAutosize"
import Button from "@material-ui/core/Button"

const Base64EncodeForm = ({onChangeForm, submitForm }) => {

    const [err, setErr] = useState('');
    const fielRef = useRef();

    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();
    const [fileContent, setFileContent] = useState('');
    const [base64Encoded, setBase64Encoded] = useState('');

    const handleChange = (e) => {
      const file = e.target.files[0];
      setFile(file);
      console.log(file); 
    }

    const base64EncodeChange = (e) => {
      //const file = e.target.files[0];
      console.log(file);
      const base64 = convertBase64(file)
    }

    const styles={
      width: "100%"
    }

    const defaultValues = {                    
        filename: '',
        addressSeparator: '||',
        chunkSeparator: ':'
    }    

    const [formValues, setFormValues] = useState(defaultValues);

    const handleInputChange = (e) => {                    
        const { name, value } = e.target
        setFormValues({
        ...formValues,
        [name]: value
        })
    }

    const convertBase64 = (file) => {

        const fileReader = new FileReader();

        fileReader.readAsText(file)
        fileReader.onload = () => {

          setFileName(file.name);
          setFileContent(fileReader.result);

          const data = fileReader.result;

          // const lines = data.split(/\r\n\|\n/);
          const lines = data.split(/\r\n/);

          // add chuck and address separators
          const processData = ':' + lines.join('|');

          // renove address separator at end of string
          const processedData = processData.slice(0, processData.length-1);
          console.log(processedData);

          // base64encode
          const base64Encoded = btoa(processedData);

          console.log(base64Encoded);
          setBase64Encoded(base64Encoded);

        }
        fileReader.onerror = (error) => {
          console.log('file error')
        }

    }
     

    return (
        <Grid container spacing={4} alignItems="left" justifyContent="left" direction="column">
            <Grid item xs={8}>
            <TextField
                id="originalFileName"
                type="file"
                inputProps={{ accept: '.xlsx, .xls, .csv, .txt' }}
                required
                label="File Name"
                name="originalFileName"
                onChange={handleChange}
                size="small"
                variant="standard"
              />
            </Grid>
            <Grid item xs={6}>
            <TextField
                id="addressseparator-input"
                name="addressSeparator"
                label="Address Separator"
                type="text"
                value={formValues.addressSeparator}
                onChange={handleInputChange}
            />
            </Grid>
            <Grid item xs={6}>
            <TextField
                id="chunkseparator-input"
                name="chunkSeparator"
                label="Chunk Separator"
                type="text"
                value={formValues.chunkSeparator}
                onChange={handleInputChange}
            />
            </Grid>
            <Grid item xs={6}>                       
                <div>
                  <Button variant="contained" color="primary" onClick={base64EncodeChange}>
                      Base64 Encode
                  </Button> &nbsp;&nbsp;
                  <Button href={`data:text/json;charset=utf-8,${encodeURIComponent( base64Encoded )}`}  download="base64EncodedFile.txt">
                  {'Download File'}
                  </Button>                  
                </div>         
            </Grid>           
            <Grid item xs={12}>
                <TextareaAutosize
                  minRows={12}
                  placeholder="File content"
                  style={styles}
                  value={fileContent}/>
            </Grid>
            <Grid item xs={12}>
                <TextareaAutosize
                  minRows={12}
                  placeholder="Base64 encoded"
                  style={styles}
                  value={base64Encoded}/>
            </Grid>
        </Grid>     
    )
}

export default Base64EncodeForm