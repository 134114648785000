import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";

const NavMenu = (props) => {

  const getNavLinkClass = (path) => {
    return props.location.pathname === path
      ? "nav-item active navItem"
      : "nav-item navItem";
  };
  return (
    <div className="p-0 border-bottom box-shadow bg-gray-800 text-gray-50" aria-labelledby="mar-batch-nav">
      <div className="mx-auto container px-[15px] flex flex-col md:flex-row md:justify-between">
        <div className='flex flex-row' tag={Link} to="/">
          <div className="mx-auto flex flex-grow title-font font-medium items-center text-gray-900 focus:outline-none cursor-pointer min-h-[48px] " tag={Link} to="/">
            <span id="mar-batch-nav" className="ml-4 text-2xl md:text-3xl text-white font-normal md:w-80">MAR2 Batch Geocoder</span>
          </div>
          <button className="mr-2 md:hidden text-white" data-te-target="#collapseNavBar" 
            type="button" data-te-collapse-init data-te-ripple-init data-te-ripple-color="light" aria-expanded="false" aria-controls="collapseNavBar">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
          </button>
        </div>
        <div className="!visible hidden md:inline-flex md:flex-row-reverse w-full flex-grow" data-te-collapse-item data-te-collapse-vertical id="collapseNavBar">
          <ul className="flex flex-col md:flex-row flex-grow justify-end">
            <li className={getNavLinkClass("/")}>
              <Link className="navLink" tag={Link} to="/">Home</Link>
            </li>
            <li className={getNavLinkClass("/batch-geocode")}>
              <Link className="navLink" tag={Link} to="/batch-geocode">Batch Geocoder</Link>
            </li>
            <li className={getNavLinkClass("/user-guide")}>
              <Link className="navLink" tag={Link} to="/user-guide">Guide</Link>
            </li>
            <li className={getNavLinkClass("/faqs")}>
              <Link className="navLink" tag={Link} to="/faqs">FAQs</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );

}
export default withRouter(NavMenu)