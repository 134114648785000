import React, { Component } from 'react';
export const Footer = () => {
    return (
        <footer className="bg-blue-900">
            <div className="container lg:w-8/12 mx-auto px-8">
                <div className="w-full flex flex-col md:flex-row py-6 md:justify-evenly">
                    <div className="lg:w-1/2">
                        <div className="text-white text-2xl">MAR 2 Tools</div>
                        <div className="text-blue-200">Copyright © 2024 OCTO</div>
                    </div>
                    <div className="lg:w-1/4 lg:ml-4 pt-2 flex flex-col text-white">
                        <div className="text-lg border-blue-200 border-b-2 w-max">Legacy MAR Links</div>
                        <div className="border-blue-200 border-b"><a className="font-light no-underline text-white hover:text-blue-200" href="http://dcatlas.dcgis.dc.gov/mar/">Legacy MAR Sample Client</a></div>
                        <div className="border-blue-200 border-b"><a className="font-light no-underline text-white hover:text-blue-200" href="https://citizenatlas.dc.gov/newwebservices/locationverifier.asmx">Legacy MAR Endpoint</a></div>
                    </div>
                    <div className="lg:w-1/4 lg:ml-4 pt-2 flex flex-col text-white">
                        <div className="text-lg border-blue-200 border-b-2 w-max">Useful Links</div>
                        <div className="border-blue-200 border-b"><a className="font-light no-underline text-white hover:text-blue-200" href="https://opendata.dc.gov/pages/mar-webservices">MAR Help</a></div>
                        <div className="border-blue-200 border-b font-light no-underline text-white">Contact Us - <a className="font-light no-underline text-white hover:text-blue-200" href="mailTo:Dev, Data (OCTO) <data.dev@dc.gov>?subject=MAR API Help">data.dev@dc.gov</a></div>
                        <div className="border-blue-200 border-b"><a className="font-light no-underline text-white hover:text-blue-200" target="_blank" href="https://forms.microsoft.com/Pages/ResponsePage.aspx?id=8Unkj5SLt0-ZBm-Tnagtcz80tedNYPVMvmt4pUNq5ORUQlVYQVkzR0NFTEFSWFlDTkpOWThNVVJETy4u">Feedback</a></div>
                    </div>
                </div>
            </div>
        </footer>
    )
}