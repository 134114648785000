import React, { Component } from 'react';
import { Route } from 'react-router';
import { Home } from './pages/Home';
import { Layout } from './pages/Layout';
import { BatchGeocode } from './pages/BatchGeocode';
import { Base64Encode } from './pages/Base64Encode';
import { FAQs } from './pages/FAQs';
import './custom.css';
import 'tw-elements';

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/' component={Home} />
        <Route path='/faqs' component={ FAQs } />
        <Route path='/base64-encode' component={Base64Encode} /> 
        <Route path='/user-guide' component={() => { window.location.replace('https://opendata.dc.gov/documents/mar-web-gecoder-user-guide/explore'); return null;} } />
        <Route path='/batch-geocode' component={BatchGeocode} />
      </Layout>
    );
  }
}