import React, { Component } from 'react';

export class FAQs extends Component {
  faqs = [
    {
      question: "Disclaimer",
      answer:
        "Neither the District of Columbia Government nor the Office of the Chief Technology Officer make any claims as to the completeness, accuracy or content of any data contained in this application; or make any representation of any kind, including, but not limited to, warranty of the accuracy or fitness for a particular use, nor are any such warranties to be implied or inferred with respect to the information or data furnished herein. The data are subject to change as modifications and updates are complete. It is understood that the information contained in the Data Center is being used at one's own risk.",
    },    
    {
      question: "What is the batch geocoder?",
      answer:
        "The batch geocoder is a tool that provides an interface to enable the bulk geocoding of records against the Master Address Repository (MAR) from a standard file type.",
    },
    {
      question: "Why is there a template?",
      answer:
        "A template is provided to ensure the appropriate fields are provided to geocode against.",
    },
    {
      question: "What if I have other non-address data in the file?",
      answer:
        "You may include other non-address fields and data in the template, however you should not modify any of the existing fields provided as part of the template.",
    },
    {
      question: "What if I have sensitive data in the file?",
      answer:
        "We strongly advise not to submit any sensitive data as part of your geocoding requests. This include Personally Identifiable Information (PII), Protected Health Information (PHI) or any other sensitive data types. We do not guarantee that this information will not be stored in logs or other auditable components of our systems. We recommend you remove this data prior to submitting your request.",
    },
    {
      question: "What types of files does it accept?",
      answer:
        "The batch geocoder accepts CSV and XLSX file types.",
    },
    {
      question: "What types of files does it return?",
      answer:
        "The batch geocoder returns XLSX file type.",
    },
    {
      question: "How many records can I submit?",
      answer:
        "Currently the recommended maximum is 10K at a time.  The processing time is about 10 mins for 10K records.",
    },
    {
      question: "What web browsers are the batch geocoder compatible with?",
      answer:
        "Currently tested browsers include: Google Chrome, Mozilla Firefox, Microsoft Edge, and Opera.",
    },
    {
      question: "Why can't I geocode places outside of DC?",
      answer:
        "The MAR provides authoritative addresses for DC only. For locations outside of DC, please use other tools such as Google or Esri to perform geocoding.",
    },
    {
      question: "Why is there issues with the returned file?",
      answer:
        "Unfortunately, we cannot accommodate for every data issue. This includes blank rows and columns inserted, columns with duplicate header names, special characters (avoid slashes '/', '') and other data anomalies. If the file returned to you has shifted rows, columns or is not completed, please review your source data to ensure it is as clean as possible before submitting the file to be geocoded.",
    },
    {
      question: "What does the Data Validation process do?",
      answer:
        "The Data Validation process checks for the following:</br></br>1. Maximum of 10,000 records (no maximum file size)</br>2. Output will be CSV file only</br>3. Special characters removed from file name</br>4. Output file is named: input_filename (cleaned of special characters) + ‘_OUT.csv’; will add YYYYMMDD</br>5. Field/column names are cleaned—alpha-numeric only (no other characters)</br>6. 5 essential columns are reordered to beginning of file; all others…sequential</br>7. Formatting will not be copied or preserve</br>8. Address and zip code are verified columns—checked against MAR; other essential columns are based on column/field name</br>9. Blank/empty columns are removed; all others are included (up to 35 columns)</br>10. Extraneous lines are removed</br>11. Special characters (control characters only) are removed from all data—to avoid skewing of columns left/right</br>12. Duplicate column names are renamed with an underscore and an integer</br>13. Blank address cells are replaced with ‘NONE_PROVIDED’</br>14. Only empty records are removed from output file</br>15. Returns calculated values if derived from functions with Excel</br>16. Errors/exceptions:</br><ul style='text-indent: 20px'><li>a. File has too many records</li><li>b. File has no records</li><li>c. File has missing columns (the essential 5)</ul></br>Please note the Data Validation and Geocoding processes are not linked. You may take the file returned from the Data Validation process and submit it to the Geocoding process",
    },        
    {
      question: "Who should I contact if I run into issues?",
      answer:
        "Please contact <a href='mailto:data.dev@dc.gov'>data.dev@dc.gov</a> if you experience any issues.",
    }  
  ];

  render () {
    return (
      <section className="text-gray-600 body-font w-full lg:w-2/3 md:w-4/5 mx-auto pt-3">
        <div className="mb-4 rounded-t-lg border border-neutral-200" id="accordionFAQ">
          {
            this.faqs.map((item, index) => {
              return <div className="border-neutral-200 border-b last:border-b-0 border-0 " key={"faq_" + index }>
                <h2 className="mb-0" id={"flush-heading" + index}>
                  <button className="group relative flex w-full items-center py-3 px-3 text-left text-base text-neutral-800 transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none [&:not([data-te-collapse-collapsed])]:bg-white [&:not([data-te-collapse-collapsed])]:text-primary [&:not([data-te-collapse-collapsed])]:[box-shadow:inset_0_-1px_0_rgba(229,231,235)]" 
                    type="button" data-te-collapse-init data-te-collapse-collapsed data-te-target={"#flush-collapse" + index}
                    aria-expanded="false" aria-controls={"flush-collapse" + index}>
                    { item.question }
                    <span className="ml-auto h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                      </svg>
                    </span>
                  </button>
                </h2>
                <div id={"flush-collapse" + index } className="!visible hidden border-0"
                  data-te-collapse-item aria-labelledby={"flush-heading" + index} data-te-parent="#accordionFAQ">
                  <div className="py-3 px-5" dangerouslySetInnerHTML={{__html: item.answer}}></div>
                </div>
              </div>
            })
          }
        </div>
      </section>
    )
  }
}