import React, { Component } from 'react';
import NavMenu from '../components/NavMenu';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
      <div className='min-h-screen flex flex-col'>
        <header className="w-full">
          <Header/>
          <NavMenu />
        </header>
        <div className='grow overflow-hidden flex mx-auto w-full md:container px-4' role="main">
          {this.props.children}
        </div>
        <Footer />
      </div>
    );
  }
}
